<template>
  <div class="container container_detail">
    <div class="detail_inner">
      <div class="navBar_m">
        <div class="leftIcon iconfont icon-fanhui" @click="back()"></div>
        <div class="navBarText_m">文章详情</div>
        <div class="rightIcons">
          <!-- <div class="collect"> -->
            <!-- <span
              class="iconfont"
              :class="iscollect ? 'icon-gray_star' : 'icon-shoucang'"
              @click="collect"
            ></span>
            <span>收藏</span>
          </div> -->
          <div class="zan" @click="drawer = true">
            <span class="iconfont icon-fenxiang"></span>
            <span>分享</span>
          </div>
        </div>
      </div>
      <el-breadcrumb class="breadCrumb" separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: `/caselist?plate_id=${plate.id}&name=${plate.name}` }">{{plate.name}}</el-breadcrumb-item>
        <el-breadcrumb-item>文章详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="line"></div>
      <div class="content">
        <!-- 标题 -->
        <div class="title">{{ details.title }}</div>
        <!-- 发布介绍 -->
        <div class="infoBox">
          <div class="c_left">
            <img class="p_img" src="@/assets/images/banner.png" alt="" />
            <div class="p_name ml10">{{ details.author }}</div>
            <div class="p_time ml10">{{ details.publish_time }}</div>
            <!-- <div class="discuss ml10">评论：120</div> -->
          </div>
          <div class="c_right">
            <!-- <div class="zan">
              <span
                class="iconfont"
                :class="iszan ? 'icon-dianzan' : 'icon-dianzan1'"
                @click="zan"
              ></span>
              <span>赞{{ details.like_sum }}</span>
            </div>
            <div class="collect pc_collect ml10">
              <span
                class="iconfont"
                :class="iscollect ? 'icon-gray_star' : 'icon-shoucang'"
                @click="collect"
              ></span>
              <span>收藏{{ details.collection }}</span>
            </div> -->
            <div class="copyLink ml10" @click="copyUrl">复制链接</div>
          </div>
        </div>
        <!-- 正文 -->
        <div class="textContent ">
         <mavon-editor
          class="md"
          :value="details.content"
          :subfield="prop.subfield"
          :defaultOpen="prop.defaultOpen"
          :toolbarsFlag="prop.toolbarsFlag"
          :editable="prop.editable"
          :scrollStyle="prop.scrollStyle"
        >
        </mavon-editor>
        </div>
        
        <!-- 底部 -->
        <div class="contentBottom">
          <span class="pre" :style="{opacity:adjacent.up==false?'0':'1'}" @click="getDetails(adjacent.up.id,plate.id)">上一篇:{{adjacent.up?adjacent.up.title : ''}}</span>
          <span class="next" :style="{opacity:adjacent.down==false?'0':'1'}" @click="getDetails(adjacent.down.id,plate.id)">下一篇:{{adjacent.down?adjacent.down.title : ''}}</span>
        </div>
      </div>
      <!-- <el-button :plain="true" @click="open2">成功</el-button> -->
      <el-drawer
        title="分享"
        :visible.sync="drawer"
        :direction="direction"
        ref="drawer"
      >
        <div class="shareWrap">
          <div class="shareBox" @click="copyUrl('wechat')">
            <img src="@/assets/images/wechat.png" alt="" />
            <div class="shareText">微信好友</div>
          </div>
          <div class="shareBox" @click="copyUrl('qq')">
            <img src="@/assets/images/qq.png" alt="" />
            <div class="shareText">QQ</div>
          </div>
          <div class="shareBox" @click="copyUrl()">
            <img src="@/assets/images/copyLink.png" alt="" />
            <div class="shareText">复制链接</div>
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import '@/assets/css/global.css'
import { mapState } from 'vuex'
export default {
  data() {
    return {
      plate_name:'',
      iszan: false,
      iscollect: false,
      drawer: false,
      direction: 'btt',
      detailsId: '',
      details: {},
      articleDetail: '',
      plate:{},
      adjacent:{}, // 上一篇/下一篇
      prop: {
        subfield: false, // 单双栏模式
        defaultOpen: 'preview', //edit： 默认展示编辑区域 ， preview： 默认展示预览区域
        editable: false,
        toolbarsFlag: false,
        scrollStyle: true
      }
    }
  },
  computed: {
    ...mapState(['mobile'])
  },
  mounted() {
    if (this.mobile) {
      this.size = true
      let navBar = document.getElementsByClassName('navWrap')[0]
      navBar.style.display = 'none'
      // let bottom = document.getElementsByClassName('bottom')[0]
      // bottom.style.display = 'none'
    }
  },
  beforeDestroy() {
  },
  created() {
     this.plate_name = this.$route.query.name
    // 视频/文章id
    let id = this.$route.query.detailsId
    // 板块id
    let plate_id = this.$route.query.plate_id
    this.plate_id = plate_id;
    // 获取视频/文章详情
    this.getDetails(id,plate_id)
  },
  methods: {
    // 获取视频/文章详情
    async getDetails(detailsId,plate_id) {
      console.log(detailsId)
      let data = {
        plate_id:plate_id,
        type:1
      }
      let { data: res } = await this.$http.get(`/view-details/${detailsId}`,{params:data})
      if (res.state == 200) {
        this.details = res.data
        this.plate = res.data.plate
        this.adjacent = res.data.adjacent
        // console.log(this.adjacent.up)
        console.log(res)
        // let content = document.getElementsByClassName("content")[0]
        // content.scrollIntoView(true)
        let top = document.documentElement.scrollTop || document.body.scrollTop
        // 实现滚动效果
        document.body.scrollTop = document.documentElement.scrollTop = top = 0
      }
    },
    zan() {
      if (this.iszan) {
        this.iszan = false
        return
      }
      this.iszan = true
    },
    collect() {
      if (this.iscollect) {
        this.iscollect = false
        return
      }
      this.iscollect = true
    },
    back() {
      if (window.history.length <= 1) {
        this.$router.push({ path: '/zh-CN/home' })
        return false
      } else {
        this.$router.go(-1)
      }
    },
    // 点击复制的方法
    copyUrl(type) {
      // 创建一个 Input标签
      const cInput = document.createElement('input')
      cInput.value = location.href
      document.body.appendChild(cInput)
      cInput.select() // 选取文本域内容;
      // 执行浏览器复制命令
      // 复制命令会将当前选中的内容复制到剪切板中（这里就是创建的input标签）
      // Input要在正常的编辑状态下原生复制方法才会生效
      document.execCommand('Copy')
      if (type == 'wechat') {
        window.location.href = 'weixin://'
      } else if (type == 'qq') {
        window.location.href = 'mqqwpa://'
      } else {
        this.$toast('复制成功!')
        this.drawer = false
      }
      /// 复制成功后再将构造的标签 移除
      cInput.remove()
    }
  }
}
</script>

<style lang="less" scoped>
.v-note-wrapper{
  z-index: 10;

}
.v-note-wrapper.shadow{
  padding: 15px 0;
  // background: #fff;
  background-color: rgb(251, 251, 251);
}
.navBar_m {
  display: none;
}
.container_detail {
  width: 100%;
  background: #fff;
}
.detail_inner {
  width: 864px;
  margin: 0 auto;
  padding-top: 56px;
  min-height: 500px;
}
.line {
  width: 864px;
  height: 1px;
  background-color: #eeeeee;
  border-radius: 1px;
  margin: 40px 0 32px 0;
}
.content {
  width: 100%;
  padding: 0 0 34px 32px;
  .title {
    width: 100%;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
    font-weight: 600;
    font-size: 25px;
    color: #333;
    margin-bottom: 21px;
  }
  .infoBox {
    width: 100%;
    height: 34px;
    display: flex;
    justify-content: space-between;
    .p_img {
      display: block;
      width: 20px;
      height: 20px;
    }
    .c_left,
    .c_right {
      height: 100%;
      display: flex;

      align-items: center;
      font-size: 12px;
    }
    .c_left {
      width: 55%;
      justify-content: flex-start;
    }
    .c_right {
      width: 40%;
      justify-content: flex-end;
    }
    .ml10 {
      margin-left: 10px;
    }
    .iconfont {
      margin-right: 5px;
      cursor: pointer;
    }
    .copyLink {
      color: #8d89e3;
      cursor: pointer;
    }
  }
  .textContent {
    width: 100%;
    min-height: 90px;
    margin: 20px 0;
  }
  .contentBottom {
    width: 100%;
    height: 40px;
    font-family: SourceHanSansSC-Regular;
    font-size: 18px;
    line-height: 40px;
    letter-spacing: 0px;
    color: #999999;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      max-width: 30%;
      cursor: pointer;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.shareWrap {
  width: 65%;
  height: 1.74rem;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  .shareBox {
    width: 2rem;
    height: 1.21rem;
    img {
      display: block;
      width: 0.98rem;
      height: 0.98rem;
      margin: 0 auto;
    }
    .shareText {
      width: 100%;
      font-family: SourceHanSansCN-Regular;
      font-size: 0.24rem;
      color: #999999;
      text-align: center;
      margin-top: 5px;
    }
  }
}
.el-drawer__header {
  margin-bottom: 20px;
  text-align: center;
}
@media screen and (max-width: 760px) {
  .container_detail {
    width: 100%;
    // height: 100%;
    margin: 0 auto;
    padding-top: 30px;
    .detail_inner {
      width: 100%;
      padding-top: 0;
    }
  }
  .contentBottom span {
    font-size: 0.24rem;
  }
  .content {
    width: 100%;
    padding: 0 0.35rem 0.34rem 0.35rem;
    .title {
      font-size: 0.3rem;
      margin-bottom: 0.43rem;
    }
    .c_left div {
      font-size: 0.2rem;
    }
    .discuss {
      display: none;
    }
    .c_right {
      .zan span {
        font-size: 0.24rem;
      }
    }
  }
  .textContent {
    font-family: SourceHanSansSC-Regular;
    font-size: 0.24rem;
    line-height: 0.36rem;
    color: #666666;
  }
  .line,
  .pc_collect,
  .copyLink {
    display: none;
  }
  .content .infoBox .c_left{
    width: 100%;
  }
  .content .infoBox .c_right{
    display: none;
  }
  .breadCrumb {
    display: none;
  }
  .rightIcons {
    width: 100px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .zan {
      margin-left: 5px;
    }
    .icon-fenxiang {
      font-size: 0.3rem;
    }
    span {
      font-size: 0.24rem;
    }
  }
  // 显示移动端navBar
  .navBar_m {
    display: block;
    width: 100%;
    height: 46px;
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    .leftIcon {
      width: 40px;
      height: 100%;
      font-size: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #999999;
      position: relative;
      z-index: 6;
    }
    .navBarText_m {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      font-family: SourceHanSansSC-Regular;
      font-size: 18px;
      color: #333333;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .content .contentBottom span{
    max-width: 40%;
  }
}
</style>
