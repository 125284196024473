<template>
  <div class="container container_feedback">
    <el-form ref="form" :model="form" label-width="80px" :label-position="labelPosition"
      v-if="currentTab == 0"
      :rules="rules"
    >
      <div class="title">意见反馈</div>
      <div class="title2">填写下面表格,描述你的问题</div>
      <div class="nt">
        <el-form-item label="姓名" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="联系方式" prop="phone">
          <el-input v-model="form.phone" type="phone"></el-input>
        </el-form-item>
      </div>
      <el-form-item class="desc" label="问题描述" prop="desc">
        <el-input type="textarea" v-model="form.desc"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" class="submit" @click="onSubmit"
          >提交</el-button
        >
      </el-form-item>
    </el-form>
    <!-- 回复通知 -->
    <div class="reply" v-else-if="currentTab==1">
      <div class="replyItem" v-for="item in replyList" :key="item.id" @click="checkoutDetails(item.id)">
        <div class="time">{{ item.time }}</div>
        <div class="itemText">{{ item.content }}</div>
        <div class="status">{{ item.status == 1 ? '已解决' : '' }}</div>
        <span class="iconfont icon-jiantou"></span>
      </div>
      <el-pagination :hide-on-single-page="single" small layout="prev, pager, next" :page-size="10" :total="7"></el-pagination>
    </div>
    <div class="replyDetails" v-else>
        <div class="detailsTitle1">
            我的反馈：
        </div>
        <div class="myReply">我的反馈我的反馈我的反馈我的反馈我的反馈我的反馈我的反馈我的反馈我的反馈我的反馈我的反馈我的反馈我的反馈我的反馈我的反馈我的反馈我的反馈我的反馈我的反馈我的反
馈我的反馈我的反馈我的反馈我的...</div>
        <div class="replyTime">2020-02-22 15:32</div>
        <div class="line"></div>
        <div class="detailsTitle1">
            平台回复：
        </div>
        <div class="myReply">我的反馈我的反馈我的反馈我的反馈我的反馈我的反馈我的反馈我的反馈我的反馈我的反馈我的反馈我的反馈我的反馈我的反馈我的反馈我的反馈我的反馈我的反馈我的反馈我的反
馈我的反馈我的反馈我的反馈我的...</div>
        <div class="replyTime">2020-02-22 15:32</div>
    </div>
    <!-- 左侧tab -->
    <div class="leftTab">
      <div
        class="tab"
        :class="currentTab == item.id ? 'active' : ''"
        @click="tabChange(item.id)"
        v-for="item in tabs"
        :key="item.id"
      >
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
import { isPhone } from '../assets/js/rules.js'
export default {
  data() {
    let validateMobile = (rule, value, callback) => {
      if (!isPhone(value)) {
        callback(new Error("请输入正确的手机号码"));
      } else {
        callback();
      }
    };
    return {
      labelPosition: 'top',
      single:true,
      form: {
        name: '',
        phone: '',
        desc: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入您的姓名', trigger: 'blur' },
          { min: 3, max: 5, message: '长度在 3 到 50 个字符', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { validator: validateMobile, trigger: "blur" },
        ],
        desc: [{ required: true, message: '请填写问题描述', trigger: 'blur' }],
      },
      currentTab: 0,
      tabs: [
        { name: '意见反馈', id: 0 },
        { name: '回复通知', id: 1 }
      ],
      replyList: [
        {
          id: 0,
          content:
            'sdfsf四大发明大哥灌灌灌灌苟富贵请问sdfsf四sdfsf四大发明大哥灌灌灌灌苟富贵请问我sdfsf四大发明大哥灌灌灌灌sdfsf四大发明大哥灌灌灌灌苟富贵请问我苟富贵请问我sdfsf四大发明大哥灌灌灌灌苟富贵请问我sdfsf四大发明大哥灌灌灌灌苟富贵请问我sdfsf四大发明大哥灌灌灌灌苟富贵请问我大发明大哥灌灌灌灌苟富贵请问我sdfsf四大发明大哥灌灌灌灌苟富贵请问我sdfsf四大发明大哥灌灌灌灌苟富贵请问我我',
          time: '2021-02-18 16:17',
          status: 0
        },
        {
          id: 1,
          content:
            'sdfsdfsf四大发明大哥灌灌灌灌苟富贵请问我sdfsf四大发明大哥灌灌灌灌苟富贵请问我sdfsf四大发明大哥灌灌灌灌苟富贵请问我sf',
          time: '2021-02-18 16:17',
          status: 1
        },
        { id: 2, content: 'sdfsf', time: '2021-02-18 16:17', status: 0 },
        { id: 3, content: 'sdfsf', time: '2021-02-18 16:17', status: 0 },
        { id: 4, content: 'sdfsf', time: '2021-02-18 16:17', status: 0 },
        { id: 5, content: 'sdfsf', time: '2021-02-18 16:17', status: 0 },
        { id: 6, content: 'sdfsf', time: '2021-02-18 16:17', status: 0 },
      ]
    }
  },
  mounted(){
    if (this.mobile) {
      this.size = true
      let navBar = document.getElementsByClassName('navWrap')[0]
      navBar.style.display = 'none'
      // let bottom = document.getElementsByClassName('bottom')[0]
      // bottom.style.display = 'none'
    }else{
      // let bottom = document.getElementsByClassName('bottom')[0]
      // bottom.style.display = 'block'
    }
  },
  methods: {
    onSubmit() {
      console.log('submit!')
    },
    tabChange(index) {
      this.currentTab = index
    },
    checkoutDetails(id){
      this.currentTab = 3
    }
  }
}
</script>

<style lang="less">
.el-pagination {
  display: flex;
  justify-content: center;
}
.container_feedback {
  width: 1034px;
  background-color: #ffffff;
  margin: 0 auto;
  margin-top: 60px;
  box-sizing: border-box;
  position: relative;
  .el-form {
    padding: 48px 72px;
  }
  .title {
    width: 100%;
    height: 40px;
    font-family: SourceHanSansSC-Bold;
    font-size: 26px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 40px;
    letter-spacing: 0px;
    color: #333333;
    text-align: center;
  }
  .title2 {
    width: 100%;
    height: 40px;
    font-family: SourceHanSansSC-Regular;
    font-size: 18px;
    line-height: 40px;
    letter-spacing: 0px;
    color: #333333;
    text-align: center;
    margin-bottom: 10px;
  }
  .nt {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .el-form-item {
      width: 45%;
    }
  }
  .el-textarea__inner {
    height: 106px;
  }
  .submit {
    width: 188px;
    height: 58px;
    background-color: #ffb11a;
    border-radius: 28px;
    border: none;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
}
.leftTab {
  width: 127px;
  height: 122px;
  background-color: #ffffff;
  border-radius: 10px;
  position: absolute;
  left: -143px;
  top: 0;
  z-index: 3;
  overflow: hidden;
  .tab {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  // .tab:hover{
  //     background: #2e333f;
  //     color: #fff;
  // }
  .active {
    background: #2e333f;
    color: #fff;
  }
}
.reply {
  padding: 27px 0;
}
.replyItem {
  width: 100%;
  height: 69px;
  position: relative;
  cursor: pointer;
  padding: 6px 20px 0 20px;
  .time {
    width: 100%;
    height: 16px;
    font-family: SourceHanSansCN-Normal;
    font-size: 12px;
    // line-height: 240px;
    letter-spacing: 0px;
    color: #82858c;
    text-align: right;
  }
  .itemText {
    width: 70%;
    height: 31px;
    float: left;
    font-family: SourceHanSansCN-Regular;
    font-size: 12px;
    // line-height: 240px;
    letter-spacing: 0px;
    color: #82858c;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  .status {
    width: 60px;
    height: 100%;
    font-family: SourceHanSansSC-Regular;
    font-size: 16px;
    color: #60a22b;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .iconfont {
    width: 30px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    color: #333;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.replyItem:hover {
  background: #fff;
  transform: scale(1.01);
  transition: 0.2s linear;
  transform: translateZ(0);
  box-shadow: 0px 1px 5px #cdcdcd;
  border-radius: 5px;
}
.replyDetails{
  width: 100%;
  padding: 18px 22px;
  .line{
    width: 100%;
    height: 1px;
    background-color: #ffffff;
    background: #eeeeee;
  }
}
.detailsTitle1{
  width: 100%;
	height: 40px;
	font-family: SourceHanSansSC-Regular;
	font-size: 16px;
	line-height: 40px;
	letter-spacing: 0px;
	color: #333333;
}
.myReply{
  width: 100%;
	font-family: SourceHanSansCN-Regular;
	font-size: 12px;
	line-height: 20px;
	color: #82858c;
  text-align: justify;
}
.replyTime{
  width: 100%;
	font-family: SourceHanSansCN-Normal;
	font-size: 12px;
	line-height: 40px;
	color: #82858c;
  text-align: right;
}
</style>
